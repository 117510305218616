import React, { useState } from 'react';
import OpenAI from "openai";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const openai = new OpenAI({apiKey: API_KEY, dangerouslyAllowBrowser: true});

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
    </div>
  );
};

function App() {
  const [prompt, setPrompt] = useState('');
  const [images, setImages] = useState([]);
  const [loading, isLoading] = useState(false);

  
  const generateImages = async () => {
    isLoading(true);
    try {
      const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: prompt,
        size: "1024x1024",
      })
      setImages(response.data);
    } catch (error) {
      console.error('Error generating images:', error);
    } finally {
      isLoading(false);
    }
  };


  return (
    <div className="container mx-auto my-8 text-center">
      <h1 className="text-4xl font-bold mb-4">Image Generator</h1>
      <input
        type="text"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Enter a prompt"
        className="border p-2 mb-4"
      />
      <button onClick={generateImages} className="bg-blue-500 text-white px-4 py-2 rounded">
        Generate Images
      </button>
      {loading ? <Loading/> :  
      <div className="mt-8 px-60 size-full md:size-auto">
        {images.map((image, index) => (
          <img key={index} src={image.url} alt={''} className="max-w-full mb-4" />
        ))}
        
      </div>}
    </div>
  );
}

export default App;

